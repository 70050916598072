<template>
  <v-container fluid class="d-flex justify-center" v-if="!isLoading">
    <div class="d-flex flex-column" style="min-width: 80%">
      <v-alert dense border="left" outlined type="warning" v-if="!is_logged_in">
        You must be <strong>logged in</strong> to post on the boards.
      </v-alert>
      <new-thread-dialog
        :show="new_thread"
        :topic_title="topic.title"
        :topic_id="topic.id"
        @close="new_thread = false"
        @submit="post_thread"
      />
      <v-sheet rounded>
        <v-breadcrumbs :items="breadcrumbs">
          <template v-slot:divider>
            <v-icon>mdi-forward</v-icon>
          </template>
        </v-breadcrumbs>
      </v-sheet>

      <h1>{{ topic.title }}</h1>
      <h2>{{ topic.description }}</h2>
      <v-divider class="ma-5" />
      <div class="d-flex justify-end mb-5" v-if="is_logged_in">
        <v-btn
          @click="new_thread = true"
          color="primary"
          v-if="!topic.locked || is_admin"
        >
          Start New Thread
        </v-btn>
      </div>
      <h2 v-if="!threads.length" style="text-align: center">
        No one here but us chickens.
      </h2>
      <forum-thread-card
        v-for="thread in threads"
        :key="thread.key"
        :thread="thread"
      />
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import Vue from "vue";
import store from "@/store";

import {
  FETCH_BOARD_TOPICS,
  FETCH_BOARD_THREADS,
  SUBMIT_BOARD_THREAD,
} from "@/store/actions.type";
import { BEGIN_LOADING, END_LOADING } from "@/store/mutations.type";

import ForumThreadCard from "@/components/Boards/ForumThreadCard.vue";
import NewThreadDialog from "@/components/Dialogs/NewThreadDialog.vue";

export default Vue.extend({
  name: "BoardTopic",
  props: {
    topicId: {
      type: Number,
      required: true,
    },
  },
  components: {
    ForumThreadCard,
    NewThreadDialog,
  },
  computed: {
    ...mapGetters([
      "isLoading",
      "board_topics",
      "threads",
      "is_logged_in",
      "is_admin",
    ]),
    topic: function () {
      return this.board_topics.find((t) => t.id == this.topicId);
    },
    breadcrumbs: function () {
      return [
        {
          text: "Boards",
          disabled: false,
          href: "/boards",
        },
        {
          text: this.topic.title,
          disabled: false,
          href: `/boards/topic/${this.topicId}`,
        },
      ];
    },
  },
  created() {
    store.commit(BEGIN_LOADING);
    Promise.all([
      store.dispatch(FETCH_BOARD_TOPICS),
      store.dispatch(FETCH_BOARD_THREADS, this.topicId),
    ]).then(() => {
      store.commit(END_LOADING);
    });
  },
  methods: {
    post_thread: function (thread_info) {
      console.log(thread_info);
      store.commit(BEGIN_LOADING);
      store.dispatch(SUBMIT_BOARD_THREAD, thread_info).then((thread_id) => {
        this.$router.push(`/boards/thread/${thread_id}`);
      });
    },
  },
  data: () => ({
    new_thread: false,
  }),
});
</script>
