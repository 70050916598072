<template>
  <div class="thread-container">
    <v-sheet shaped outlined class="thread-content pl-6 pt-2 pb-2">
      <div class="text-h5">
        <router-link
          :to="`/boards/thread/${thread.id}`"
          class="text-h5 font-button"
        >
          {{ thread.title }}
          <v-icon v-if="thread.admin_write_only">mdi-lock</v-icon>
        </router-link>
      </div>
      <div class="text-subtitle-1 font-weight-light">
        {{ thread.description }}
      </div>
      <div class="text-caption" v-if="thread.forum_name">
        Started by
        <router-link :to="`/profile/${thread.poster_user_id}`">{{
          thread.forum_name
        }}</router-link>
      </div>
    </v-sheet>
    <v-sheet
      rounded
      class="thread-details d-flex justify-center align-center text-overline"
      style="opacity: 0.9"
      elevation="6"
      color="teal"
    >
      <router-link
        :to="`/profile/${thread.poster_user_id}`"
        style="color: white"
        class="mx-2"
        >{{ thread.forum_name }}</router-link
      >
      on {{ new Date(thread.latest_post_time * 1000).friendlyString() }}
    </v-sheet>
    <div class="thread-replies text-h6" v-if="thread.message_count">
      {{ thread.message_count }} replies
    </div>
  </div>
</template>

<script>
export default {
  name: "ForumThreadCard",
  props: {
    thread: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
.thread-container {
  display: grid;
  grid-template-columns: 1fr 1.2fr;
  grid-template-rows: 1fr 1em 1em;
}

.thread-content {
  grid-column: 1 / 3;
  grid-row: 1 / 3;
}

.thread-details {
  grid-column: 2;
  grid-row: 2 / 4;
  margin-right: 2em;
}

.thread-replies {
  grid-column: 2;
  grid-row: 1;
  margin-right: 2em;
  margin-top: 1em;
  text-align: right;
}
</style>
