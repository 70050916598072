<template>
  <v-dialog v-model="show" persistent max-width="60%" min-width="300px">
    <v-card>
      <v-card-title class="text-h5"> New Thread </v-card-title>
      <v-card-subtitle v-if="topic_title">{{ topic_title }}</v-card-subtitle>
      <v-card-text>
        <v-divider class="mb-5" />
        <v-container class="d-flex flex-column">
          <v-text-field
            label="Thread Title"
            placeholder="Cereal or Milk First?"
            v-model="title"
            outlined
            dense
          />
          <v-text-field
            label="Thread Description"
            placeholder="What kind of monster puts milk first?"
            v-model="description"
            outlined
            dense
          />
          <h3>Message</h3>
          <v-container class="d-flex flex-column">
            <v-textarea
              filled
              auto-grow
              outlined
              v-model="message"
            ></v-textarea>
          </v-container>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red darken-1" text @click="$emit('close')"> Abort </v-btn>
        <v-btn color="green darken-1" text @click="doPost"> Post </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "NewThreadDialog",
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    topic_title: {
      type: String,
      required: false,
    },
    topic_id: {
      type: Number,
      required: true,
    },
  },
  components: {},
  computed: {
    ...mapGetters(["user_profile"]),
  },
  methods: {
    doPost: function () {
      this.$emit("submit", {
        topic_id: this.topic_id,
        title: this.title,
        description: this.description,
        message: this.message,
      });
    },
  },
  data: () => ({
    title: "",
    description: "",
    message: "",
  }),
};
</script>
